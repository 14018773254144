<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="item.isLoading"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @downloadFile="onDownloadFile"
    @save="onSave('CustomerSettingsDatabaseTypes')"
    @cancel="onCancel('CustomerSettingsDatabaseTypes')"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('editableList');
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'CustomerSettingsDatabaseTypeEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      controllerName: 'CustomerSettingsDatabaseTypes',
      pageHeader: 'Редактирование вида БД',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Виды БД',
          route: { name: 'CustomerSettingsDatabaseTypes' },
        },
        {
          text: 'Редактирование вида БД',
        },
      ],
      form: {},
    };
  },

  computed: {
    ...mapState(['item']),

    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Наименование',
            },
            {
              type: 'file',
              key: 'imageFile',
              label: 'Файл',
            },
            {
              type: 'checkbox',
              key: 'unloadingComplete',
              label: 'Выгрузка разработана',
            },
          ],
        },
      ];
    },
  },

  created() {
    this.getItem({ name: this.controllerName, id: this.id }).then(() => {
      this.form = this.lodash.cloneDeep(this.item.data);
    });
  },

  methods: {
    ...mapActions(['getItem', 'updateItem', 'downloadFile']),

    onDownloadFile() {
      this.downloadFile({
        name: this.controllerName,
        id: this.id,
        type: 'file',
      });
    },
  },
};
</script>
